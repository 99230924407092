.map-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.map-base-button {
  border: none;
  border-radius: 2px;
  background-color: white;
  transition: 0.2s;
  position: relative;
  height: 30px;
  width: 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.map-base-button:hover {
  background-color: #f1f1f1;
}

.current-position-button {
  top: 10px;
  right: 5px;
}

.map-select {
  padding: 5px;
  border-radius: 2px;
  border: none;
  background-color: white;
  font-size: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  outline: none;
  color: #666;
  transition: 0.2s;
}

.map-select:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.map-select-view {
  position: relative;
  top: 10px;
  left: 5px;
}

.map-zoom-control-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 30px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  border-radius: 2px;
}

.map-zoom-control-button {
  transition: 0.2s;
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  height: 30px;
}

.map-zoom-control-button:hover {
  background-color: #f1f1f1;
}

.map-zoom-control-line {
  margin: 0;
  width: 80%;
}

.map-zoom-view-container {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

/* ---------------------------- */

@media screen and (min-width: 768px) {
  .map-button-red {
    padding: 0.25rem 2rem;
  }
}
.map-button-red {
  border: 1px #d2190e solid;
  background: #d2190e;
  color: white;
  /* margin: 20px 0px; */
  padding: 0.25rem 1rem;
  border-radius: 5px;
  transition: 0.2s;
}

.map-button-red:hover {
  background: #b2190e;
  color: white;
}

.map-button-red-space {
  margin-top: 10px;
  margin-right: 15px;
}
