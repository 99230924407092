$sidebar-bg-color: #ffffff !default;
$sidebar-color: #000000 !default;
$highlight-color: #ff0000 !default;
$submenu-bg-color: #ffffff !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";
@import url(https://fonts.googleapis.com/css?family=Poppins|Bebas+Neue|Work+Sans|Pacifico|Roboto:100,200,300,400,500,600,700);

@font-face {
  font-family: "Cera Round Pro Regular";
  src: local("Cera Round Pro Regular"),
    url("./fonts/cera-round-pro/TypeMates-CeraRoundProRegular.woff")
      format("woff");
}
@font-face {
  font-family: "Cera Round Pro Medium";
  src: local("Cera Round Pro Medium"),
    url("./fonts/cera-round-pro/TypeMates-CeraRoundProMedium.woff")
      format("woff");
}
@font-face {
  font-family: "Bebas Neue Bold";
  src: local("Bebas Neue Bold"),
    url("./fonts/bebas-neue/BebasNeue-Bold.otf") format("opentype"),
    url("./fonts/bebas-neue/BebasNeue-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Bebas Neue Book";
  src: local("Bebas Neue Book"),
    url("./fonts/bebas-neue/BebasNeue-Book.otf") format("opentype"),
    url("./fonts/bebas-neue/BebasNeue-Book.ttf") format("truetype");
}

//regular fonts
.poppins-light-7 {
  font: 300 0.43rem/0.5rem Poppins;
}
.poppins-regular-7 {
  font: 400 0.43rem/0.5rem Poppins;
}
.poppins-medium-7 {
  font: 500 0.43rem/0.5rem Poppins;
}
.poppins-semibold-7 {
  font: 600 0.43rem/0.5rem Poppins;
}
.poppins-bold-7 {
  font: 700 0.43rem/0.5rem Poppins;
}
.poppins-light-8 {
  font: 300 0.5rem/0.75rem Poppins;
}
.poppins-regular-8 {
  font: 400 0.5rem/0.75rem Poppins;
}
.poppins-medium-8 {
  font: 500 0.5rem/0.75rem Poppins;
}
.poppins-semibold-8 {
  font: 600 0.5rem/0.75rem Poppins;
}
.poppins-bold-8 {
  font: 700 0.5rem/0.75rem Poppins;
}
.poppins-light-9 {
  font: 300 0.56rem/0.68rem Poppins;
}
.poppins-regular-9 {
  font: 400 0.56rem/0.68rem Poppins;
}
.poppins-medium-9 {
  font: 500 0.56rem/0.68rem Poppins;
}
.poppins-semibold-9 {
  font: 600 0.56rem/0.68rem Poppins;
}
.poppins-bold-9 {
  font: 700 0.56rem/0.68rem Poppins;
}
.poppins-light-10 {
  font: 300 0.63rem/0.75rem Poppins;
}
.poppins-regular-10 {
  font: 400 0.63rem/0.75rem Poppins;
}
.poppins-medium-10 {
  font: 500 0.63rem/0.75rem Poppins;
}
.poppins-semibold-10 {
  font: 600 0.63rem/0.75rem Poppins;
}
.poppins-bold-10 {
  font: 700 0.63rem/0.75rem Poppins;
}
.poppins-light-11 {
  font: 300 0.68rem/0.87rem Poppins;
}
.poppins-regular-11 {
  font: 400 0.68rem/0.87rem Poppins;
}
.poppins-medium-11 {
  font: 500 0.68rem/0.87rem Poppins;
}
.poppins-semibold-11 {
  font: 600 0.68rem/0.87rem Poppins;
}
.poppins-bold-11 {
  font: 700 0.68rem/0.87rem Poppins;
}
.poppins-light-12 {
  font: 300 0.75rem/0.9rem Poppins;
}
.poppins-regular-12 {
  font: 400 0.75rem/0.9rem Poppins;
}
.poppins-medium-12 {
  font: 500 0.75rem/0.9rem Poppins;
}
.poppins-semibold-12 {
  font: 600 0.75rem/0.9rem Poppins;
}
.poppins-bold-12 {
  font: 700 0.75rem/0.9rem Poppins;
}
.poppins-light-13 {
  font: 300 0.81rem/1rem Poppins;
}
.poppins-regular-13 {
  font: 400 0.81rem/1rem Poppins;
}
.poppins-medium-13 {
  font: 500 0.81rem/1rem Poppins;
}
.poppins-semibold-13 {
  font: 600 0.81rem/1rem Poppins;
}
.poppins-bold-13 {
  font: 700 0.81rem/1rem Poppins;
}
.poppins-light-14 {
  font: 300 0.87rem/1.06rem Poppins;
}
.poppins-regular-14 {
  font: 400 0.87rem/1.06rem Poppins;
}
.poppins-medium-14 {
  font: 500 0.87rem/1.06rem Poppins !important;
  color: black;
}
.poppins-semibold-14 {
  font: 600 0.87rem/1.06rem Poppins;
  color: black;
}
.poppins-semibold-14:hover {
  font: 600 0.87rem/1.06rem Poppins;
  color: black;
}
.poppins-bold-14 {
  font: 600 0.87rem/1.06rem Poppins;
}
.poppins-light-15 {
  font: 300 0.94rem/1.5rem Poppins;
}
.poppins-regular-15 {
  font: 400 0.94rem/1.5rem Poppins;
}
.poppins-medium-15 {
  font: 500 0.94rem/1.5rem Poppins;
}
.poppins-semibold-15 {
  font: 600 0.94rem/1.5rem Poppins;
}
.poppins-bold-15 {
  font: 700 0.94rem/1.5rem Poppins;
}
.poppins-light-16 {
  font: 300 1rem/1.5rem Poppins;
}
.poppins-regular-16 {
  font: 400 1rem/1.5rem Poppins;
}
.poppins-medium-16 {
  font: 500 1rem/1.5rem Poppins;
}
.poppins-semibold-16 {
  font: 600 1rem/1.5rem Poppins;
}
.poppins-bold-16 {
  font: 700 1rem/1.5rem Poppins;
}
.poppins-light-17 {
  font: 300 1.06rem/1.5rem Poppins;
}
.poppins-regular-17 {
  font: 400 1.06rem/1.5rem Poppins;
}
.poppins-medium-17 {
  font: 500 1.06rem/1.5rem Poppins;
}
.poppins-semibold-17 {
  font: 600 1.06rem/1.5rem Poppins;
}
.poppins-bold-17 {
  font: 700 1.06rem/1.5rem Poppins;
}
.poppins-light-18 {
  font: 300 1.13rem/1.5rem Poppins;
}
.poppins-regular-18 {
  font: 400 1.13rem/1.5rem Poppins;
}
.poppins-medium-18 {
  font: 500 1.13rem/1.5rem Poppins;
}
.poppins-semibold-18 {
  font: 600 1.13rem/1.5rem Poppins;
}
.poppins-bold-18 {
  font: 700 1.13rem/1.5rem Poppins;
}
.poppins-light-19 {
  font: 300 1.19rem/1.5rem Poppins;
}
.poppins-regular-19 {
  font: 400 1.19rem/1.5rem Poppins;
}
.poppins-medium-19 {
  font: 500 1.19rem/1.5rem Poppins;
}
.poppins-semibold-19 {
  font: 600 1.19rem/1.5rem Poppins;
}
.poppins-bold-19 {
  font: 700 1.19rem/1.5rem Poppins;
}
.poppins-light-20 {
  font: 300 1.25rem/1.5rem Poppins;
}
.poppins-regular-20 {
  font: 400 1.25rem/1.5rem Poppins;
}
.poppins-medium-20 {
  font: 500 1.25rem/1.5rem Poppins;
}
.poppins-semibold-20 {
  font: 600 1.25rem/1.5rem Poppins;
}
.poppins-bold-20 {
  font: 700 1.25rem/1.5rem Poppins;
}
.poppins-light-22 {
  font: 300 1.38rem/1.5rem Poppins;
}
.poppins-regular-22 {
  font: 400 1.38rem/1.5rem Poppins;
}
.poppins-medium-22 {
  font: 500 1.38rem/1.5rem Poppins;
}
.poppins-semibold-22 {
  font: 600 1.38rem/1.5rem Poppins;
}
.poppins-bold-22 {
  font: 700 1.38rem/1.5rem Poppins;
}
.poppins-light-24 {
  font: 300 1.5rem/1.5rem Poppins;
}
.poppins-regular-24 {
  font: 400 1.5rem/1.5rem Poppins;
}
.poppins-medium-24 {
  font: 500 1.5rem/1.5rem Poppins;
}
.poppins-semibold-24 {
  font: 600 1.5rem/1.5rem Poppins;
}
.poppins-bold-24 {
  font: 700 1.5rem/1.5rem Poppins;
}
.poppins-light-25 {
  font: 300 1.56rem/1.5rem Poppins;
}
.poppins-regular-25 {
  font: 400 1.56rem/1.5rem Poppins;
}
.poppins-medium-25 {
  font: 500 1.56rem/1.5rem Poppins;
}
.poppins-semibold-25 {
  font: 600 1.56rem/1.5rem Poppins;
}
.poppins-bold-25 {
  font: 700 1.56rem/1.5rem Poppins;
}
.poppins-light-26 {
  font: 300 1.63rem/1.5rem Poppins;
}
.poppins-regular-26 {
  font: 400 1.63rem/1.5rem Poppins;
}
.poppins-medium-26 {
  font: 500 1.63rem/1.5rem Poppins;
}
.poppins-semibold-26 {
  font: 600 1.63rem/1.5rem Poppins;
}
.poppins-bold-26 {
  font: 700 1.63rem/1.5rem Poppins;
}
.poppins-light-27 {
  font: 300 1.69rem/1.5rem Poppins;
}
.poppins-regular-27 {
  font: 400 1.69rem/1.5rem Poppins;
}
.poppins-medium-27 {
  font: 500 1.69rem/1.5rem Poppins;
}
.poppins-semibold-27 {
  font: 600 1.69rem/1.5rem Poppins;
}
.poppins-bold-27 {
  font: 700 1.69rem/1.5rem Poppins;
}
.poppins-light-28 {
  font: 300 1.75rem/1.5rem Poppins;
}
.poppins-regular-28 {
  font: 400 1.75rem/1.5rem Poppins;
}
.poppins-medium-28 {
  font: 500 1.75rem/1.5rem Poppins;
}
.poppins-semibold-28 {
  font: 600 1.75rem/1.5rem Poppins;
}
.poppins-bold-28 {
  font: 700 1.75rem/1.5rem Poppins;
}
.poppins-light-30 {
  font: 300 1.88rem/1.5rem Poppins;
}
.poppins-regular-30 {
  font: 400 1.88rem/1.5rem Poppins;
}
.poppins-medium-30 {
  font: 500 1.88rem/1.5rem Poppins;
}
.poppins-semibold-30 {
  font: 600 1.88rem/1.5rem Poppins;
}
.poppins-bold-30 {
  font: 700 1.88rem/1.5rem Poppins;
}
.poppins-light-32 {
  font: 300 2rem/2.4rem Poppins;
}
.poppins-regular-32 {
  font: 400 2rem/2.4rem Poppins;
}
.poppins-medium-32 {
  font: 500 2rem/2.4rem Poppins;
}
.poppins-semibold-32 {
  font: 600 2rem/2.4rem Poppins;
}
.poppins-bold-32 {
  font: 700 2rem/2.4rem Poppins;
}
.poppins-light-36 {
  font: 300 2.25rem/2.4rem Poppins;
}
.poppins-regular-36 {
  font: 400 2.25rem/2.4rem Poppins;
}
.poppins-medium-36 {
  font: 500 2.25rem/2.4rem Poppins;
}
.poppins-semibold-36 {
  font: 600 2.25rem/2.4rem Poppins;
}
.poppins-bold-36 {
  font: 700 2.25rem/2.4rem Poppins;
}
.poppins-light-39 {
  font: 300 2.44rem/2.4rem Poppins;
}
.poppins-regular-39 {
  font: 400 2.44rem/2.4rem Poppins;
}
.poppins-medium-39 {
  font: 500 2.44rem/2.4rem Poppins;
}
.poppins-semibold-39 {
  font: 600 2.44rem/2.4rem Poppins;
}
.poppins-bold-39 {
  font: 700 2.44rem/2.4rem Poppins;
}
.poppins-semibold-40 {
  font: 600 2.5rem/2.4rem Poppins;
}
.poppins-semibold-54 {
  font: 600 3.38rem/2.4rem Poppins;
}
.bebas-neue-regular-21 {
  font: 400 1.31rem/1.81rem Bebas Neue;
}
.bebas-neue-regular-25 {
  font: 400 1.56rem/1.81rem Bebas Neue;
}
.bebas-neue-regular-28 {
  font: 400 1.75rem/1.81rem Bebas Neue;
}
.bebas-neue-regular-32 {
  font: 400 2.08rem/2.51rem Bebas Neue;
}
.bebas-neue-regular-38 {
  font: 400 2.38rem/2.81rem Bebas Neue;
}
.bebas-neue-regular-40 {
  font: 400 2.5rem/2.81rem Bebas Neue;
}
.bebas-neue-regular-54 {
  font: 400 3.38rem/2.81rem Bebas Neue;
}
.bebas-neue-bold-21 {
  font: 400 1.31rem/1.81rem "Bebas Neue Bold";
}
.bebas-neue-bold-25 {
  font: 400 1.56rem/1.81rem "Bebas Neue Bold";
}
.bebas-neue-bold-28 {
  font: 400 1.75rem/1.81rem "Bebas Neue Bold";
}
.bebas-neue-bold-32 {
  font: 400 2.08rem/2.51rem "Bebas Neue Bold";
}
.bebas-neue-bold-38 {
  font: 400 2.38rem/2.81rem "Bebas Neue Bold";
}
.bebas-neue-bold-40 {
  font: 400 2.5rem/2.81rem "Bebas Neue Bold";
}
.bebas-neue-bold-54 {
  font: 400 3.38rem/2.81rem "Bebas Neue Bold";
}
.bebas-neue-book-21 {
  font: 400 1.31rem/1.81rem "Bebas Neue Book";
}
.bebas-neue-book-25 {
  font: 400 1.56rem/1.81rem "Bebas Neue Book";
}
.bebas-neue-book-28 {
  font: 400 1.75rem/1.81rem "Bebas Neue Book";
}
.bebas-neue-book-32 {
  font: 400 2.08rem/2.51rem "Bebas Neue Book";
}
.bebas-neue-book-38 {
  font: 400 2.38rem/2.81rem "Bebas Neue Book";
}
.bebas-neue-book-40 {
  font: 400 2.5rem/2.81rem "Bebas Neue Book";
}
.bebas-neue-book-54 {
  font: 400 3.38rem/2.81rem "Bebas Neue Book";
}
.work-sans-medium-12 {
  font: 500 0.75rem/0.44rem Work Sans;
}
.work-sans-medium-23 {
  font: 500 1.44rem/1rem Work Sans;
}
.work-sans-bold-23 {
  font: 700 1.44rem/1rem Work Sans;
}
.pacifico-regular-20 {
  font: 400 1.25rem/1.5rem Pacifico;
}
.roboto-regular-16 {
  font: 400 1rem/1.5rem Roboto;
}
.cera-round-pro-medium-22 {
  font: 1.38rem/1.5rem "Cera Round Pro Medium";
}
.cera-round-pro-medium-28 {
  font: 1.75rem/1.5rem "Cera Round Pro Medium";
}
//media queries font
.poppins-light-md-7 {
  @media screen and (min-width: 768px) {
    font: 300 0.43rem/0.5rem Poppins !important;
  }
}
.poppins-regular-md-7 {
  @media screen and (min-width: 768px) {
    font: 400 0.43rem/0.5rem Poppins !important;
  }
}
.poppins-medium-md-7 {
  @media screen and (min-width: 768px) {
    font: 500 0.43rem/0.5rem Poppins !important;
  }
}
.poppins-semibold-md-7 {
  @media screen and (min-width: 768px) {
    font: 600 0.43rem/0.5rem Poppins !important;
  }
}
.poppins-bold-md-7 {
  @media screen and (min-width: 768px) {
    font: 700 0.43rem/0.5rem Poppins !important;
  }
}
.poppins-light-md-8 {
  @media screen and (min-width: 768px) {
    font: 300 0.5rem/0.75rem Poppins !important;
  }
}
.poppins-regular-md-8 {
  @media screen and (min-width: 768px) {
    font: 400 0.5rem/0.75rem Poppins !important;
  }
}
.poppins-medium-md-8 {
  @media screen and (min-width: 768px) {
    font: 500 0.5rem/0.75rem Poppins !important;
  }
}
.poppins-semibold-md-8 {
  @media screen and (min-width: 768px) {
    font: 600 0.5rem/0.75rem Poppins !important;
  }
}
.poppins-bold-md-8 {
  @media screen and (min-width: 768px) {
    font: 700 0.5rem/0.75rem Poppins !important;
  }
}
.poppins-light-md-9 {
  @media screen and (min-width: 768px) {
    font: 300 0.56rem/0.68rem Poppins !important;
  }
}
.poppins-regular-md-9 {
  @media screen and (min-width: 768px) {
    font: 400 0.56rem/0.68rem Poppins !important;
  }
}
.poppins-medium-md-9 {
  @media screen and (min-width: 768px) {
    font: 500 0.56rem/0.68rem Poppins !important;
  }
}
.poppins-semibold-md-9 {
  @media screen and (min-width: 768px) {
    font: 600 0.56rem/0.68rem Poppins !important;
  }
}
.poppins-bold-md-9 {
  @media screen and (min-width: 768px) {
    font: 700 0.56rem/0.68rem Poppins !important;
  }
}
.poppins-light-md-10 {
  @media screen and (min-width: 768px) {
    font: 300 0.63rem/0.75rem Poppins !important;
  }
}
.poppins-regular-md-10 {
  @media screen and (min-width: 768px) {
    font: 400 0.63rem/0.75rem Poppins !important;
  }
}
.poppins-medium-md-10 {
  @media screen and (min-width: 768px) {
    font: 500 0.63rem/0.75rem Poppins !important;
  }
}
.poppins-semibold-md-10 {
  @media screen and (min-width: 768px) {
    font: 600 0.63rem/0.75rem Poppins !important;
  }
}
.poppins-bold-md-10 {
  @media screen and (min-width: 768px) {
    font: 700 0.63rem/0.75rem Poppins !important;
  }
}
.poppins-light-md-11 {
  @media screen and (min-width: 768px) {
    font: 300 0.68rem/0.87rem Poppins !important;
  }
}
.poppins-regular-md-11 {
  @media screen and (min-width: 768px) {
    font: 400 0.68rem/0.87rem Poppins !important;
  }
}
.poppins-medium-md-11 {
  @media screen and (min-width: 768px) {
    font: 500 0.68rem/0.87rem Poppins !important;
  }
}
.poppins-semibold-md-11 {
  @media screen and (min-width: 768px) {
    font: 600 0.68rem/0.87rem Poppins !important;
  }
}
.poppins-bold-md-11 {
  @media screen and (min-width: 768px) {
    font: 700 0.68rem/0.87rem Poppins !important;
  }
}
.poppins-light-md-12 {
  @media screen and (min-width: 768px) {
    font: 300 0.75rem/0.9rem Poppins !important;
  }
}
.poppins-regular-md-12 {
  @media screen and (min-width: 768px) {
    font: 400 0.75rem/0.9rem Poppins !important;
  }
}
.poppins-medium-md-12 {
  @media screen and (min-width: 768px) {
    font: 500 0.75rem/0.9rem Poppins !important;
  }
}
.poppins-semibold-md-12 {
  @media screen and (min-width: 768px) {
    font: 600 0.75rem/0.9rem Poppins !important;
  }
}
.poppins-bold-md-12 {
  @media screen and (min-width: 768px) {
    font: 700 0.75rem/0.9rem Poppins !important;
  }
}
.poppins-light-md-13 {
  @media screen and (min-width: 768px) {
    font: 300 0.81rem/1rem Poppins !important;
  }
}
.poppins-regular-md-13 {
  @media screen and (min-width: 768px) {
    font: 400 0.81rem/1rem Poppins !important;
  }
}
.poppins-medium-md-13 {
  @media screen and (min-width: 768px) {
    font: 500 0.81rem/1rem Poppins !important;
  }
}
.poppins-semibold-md-13 {
  @media screen and (min-width: 768px) {
    font: 600 0.81rem/1rem Poppins !important;
  }
}
.poppins-bold-md-13 {
  @media screen and (min-width: 768px) {
    font: 700 0.81rem/1rem Poppins !important;
  }
}
.poppins-light-md-14 {
  @media screen and (min-width: 768px) {
    font: 300 0.87rem/1.06rem Poppins !important;
  }
}
.poppins-regular-md-14 {
  @media screen and (min-width: 768px) {
    font: 400 0.87rem/1.06rem Poppins !important;
  }
}
.poppins-medium-md-14 {
  @media screen and (min-width: 768px) {
    font: 500 0.87rem/1.06rem Poppins !important;
  }
  color: black;
}
.poppins-semibold-md-14 {
  @media screen and (min-width: 768px) {
    font: 600 0.87rem/1.06rem Poppins !important;
  }
  color: black;
}
.poppins-semibold-md-14:hover {
  @media screen and (min-width: 768px) {
    font: 600 0.87rem/1.06rem Poppins !important;
  }
  color: black;
}
.poppins-bold-md-14 {
  @media screen and (min-width: 768px) {
    font: 600 0.87rem/1.06rem Poppins !important;
  }
}
.poppins-light-md-15 {
  @media screen and (min-width: 768px) {
    font: 300 0.94rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-15 {
  @media screen and (min-width: 768px) {
    font: 400 0.94rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-15 {
  @media screen and (min-width: 768px) {
    font: 500 0.94rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-15 {
  @media screen and (min-width: 768px) {
    font: 600 0.94rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-15 {
  @media screen and (min-width: 768px) {
    font: 700 0.94rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-16 {
  @media screen and (min-width: 768px) {
    font: 300 1rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-16 {
  @media screen and (min-width: 768px) {
    font: 400 1rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-16 {
  @media screen and (min-width: 768px) {
    font: 500 1rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-16 {
  @media screen and (min-width: 768px) {
    font: 600 1rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-16 {
  @media screen and (min-width: 768px) {
    font: 700 1rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-17 {
  @media screen and (min-width: 768px) {
    font: 300 1.06rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-17 {
  @media screen and (min-width: 768px) {
    font: 400 1.06rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-17 {
  @media screen and (min-width: 768px) {
    font: 500 1.06rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-17 {
  @media screen and (min-width: 768px) {
    font: 600 1.06rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-17 {
  @media screen and (min-width: 768px) {
    font: 700 1.06rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-18 {
  @media screen and (min-width: 768px) {
    font: 300 1.13rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-18 {
  @media screen and (min-width: 768px) {
    font: 400 1.13rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-18 {
  @media screen and (min-width: 768px) {
    font: 500 1.13rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-18 {
  @media screen and (min-width: 768px) {
    font: 600 1.13rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-18 {
  @media screen and (min-width: 768px) {
    font: 700 1.13rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-19 {
  @media screen and (min-width: 768px) {
    font: 300 1.19rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-19 {
  @media screen and (min-width: 768px) {
    font: 400 1.19rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-19 {
  @media screen and (min-width: 768px) {
    font: 500 1.19rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-19 {
  @media screen and (min-width: 768px) {
    font: 600 1.19rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-19 {
  @media screen and (min-width: 768px) {
    font: 700 1.19rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-20 {
  @media screen and (min-width: 768px) {
    font: 300 1.25rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-20 {
  @media screen and (min-width: 768px) {
    font: 400 1.25rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-20 {
  @media screen and (min-width: 768px) {
    font: 500 1.25rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-20 {
  @media screen and (min-width: 768px) {
    font: 600 1.25rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-20 {
  @media screen and (min-width: 768px) {
    font: 700 1.25rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-22 {
  @media screen and (min-width: 768px) {
    font: 300 1.38rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-22 {
  @media screen and (min-width: 768px) {
    font: 400 1.38rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-22 {
  @media screen and (min-width: 768px) {
    font: 500 1.38rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-22 {
  @media screen and (min-width: 768px) {
    font: 600 1.38rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-22 {
  @media screen and (min-width: 768px) {
    font: 700 1.38rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-24 {
  @media screen and (min-width: 768px) {
    font: 300 1.5rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-24 {
  @media screen and (min-width: 768px) {
    font: 400 1.5rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-24 {
  @media screen and (min-width: 768px) {
    font: 500 1.5rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-24 {
  @media screen and (min-width: 768px) {
    font: 600 1.5rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-24 {
  @media screen and (min-width: 768px) {
    font: 700 1.5rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-25 {
  @media screen and (min-width: 768px) {
    font: 300 1.56rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-25 {
  @media screen and (min-width: 768px) {
    font: 400 1.56rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-25 {
  @media screen and (min-width: 768px) {
    font: 500 1.56rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-25 {
  @media screen and (min-width: 768px) {
    font: 600 1.56rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-25 {
  @media screen and (min-width: 768px) {
    font: 700 1.56rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-26 {
  @media screen and (min-width: 768px) {
    font: 300 1.63rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-26 {
  @media screen and (min-width: 768px) {
    font: 400 1.63rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-26 {
  @media screen and (min-width: 768px) {
    font: 500 1.63rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-26 {
  @media screen and (min-width: 768px) {
    font: 600 1.63rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-26 {
  @media screen and (min-width: 768px) {
    font: 700 1.63rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-27 {
  @media screen and (min-width: 768px) {
    font: 300 1.69rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-27 {
  @media screen and (min-width: 768px) {
    font: 400 1.69rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-27 {
  @media screen and (min-width: 768px) {
    font: 500 1.69rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-27 {
  @media screen and (min-width: 768px) {
    font: 600 1.69rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-27 {
  @media screen and (min-width: 768px) {
    font: 700 1.69rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-28 {
  @media screen and (min-width: 768px) {
    font: 300 1.75rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-28 {
  @media screen and (min-width: 768px) {
    font: 400 1.75rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-28 {
  @media screen and (min-width: 768px) {
    font: 500 1.75rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-28 {
  @media screen and (min-width: 768px) {
    font: 600 1.75rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-28 {
  @media screen and (min-width: 768px) {
    font: 700 1.75rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-30 {
  @media screen and (min-width: 768px) {
    font: 300 1.88rem/1.5rem Poppins !important;
  }
}
.poppins-regular-md-30 {
  @media screen and (min-width: 768px) {
    font: 400 1.88rem/1.5rem Poppins !important;
  }
}
.poppins-medium-md-30 {
  @media screen and (min-width: 768px) {
    font: 500 1.88rem/1.5rem Poppins !important;
  }
}
.poppins-semibold-md-30 {
  @media screen and (min-width: 768px) {
    font: 600 1.88rem/1.5rem Poppins !important;
  }
}
.poppins-bold-md-30 {
  @media screen and (min-width: 768px) {
    font: 700 1.88rem/1.5rem Poppins !important;
  }
}
.poppins-light-md-32 {
  @media screen and (min-width: 768px) {
    font: 300 2rem/2.4rem Poppins !important;
  }
}
.poppins-regular-md-32 {
  @media screen and (min-width: 768px) {
    font: 400 2rem/2.4rem Poppins !important;
  }
}
.poppins-medium-md-32 {
  @media screen and (min-width: 768px) {
    font: 500 2rem/2.4rem Poppins !important;
  }
}
.poppins-semibold-md-32 {
  @media screen and (min-width: 768px) {
    font: 600 2rem/2.4rem Poppins !important;
  }
}
.poppins-bold-md-32 {
  @media screen and (min-width: 768px) {
    font: 700 2rem/2.4rem Poppins !important;
  }
}
.poppins-light-md-36 {
  @media screen and (min-width: 768px) {
    font: 300 2.25rem/2.4rem Poppins !important;
  }
}
.poppins-regular-md-36 {
  @media screen and (min-width: 768px) {
    font: 400 2.25rem/2.4rem Poppins !important;
  }
}
.poppins-medium-md-36 {
  @media screen and (min-width: 768px) {
    font: 500 2.25rem/2.4rem Poppins !important;
  }
}
.poppins-semibold-md-36 {
  @media screen and (min-width: 768px) {
    font: 600 2.25rem/2.4rem Poppins !important;
  }
}
.poppins-bold-md-36 {
  @media screen and (min-width: 768px) {
    font: 700 2.25rem/2.4rem Poppins !important;
  }
}
.poppins-light-md-39 {
  @media screen and (min-width: 768px) {
    font: 300 2.44rem/2.4rem Poppins !important;
  }
}
.poppins-regular-md-39 {
  @media screen and (min-width: 768px) {
    font: 400 2.44rem/2.4rem Poppins !important;
  }
}
.poppins-medium-md-39 {
  @media screen and (min-width: 768px) {
    font: 500 2.44rem/2.4rem Poppins !important;
  }
}
.poppins-semibold-md-39 {
  @media screen and (min-width: 768px) {
    font: 600 2.44rem/2.4rem Poppins !important;
  }
}
.poppins-bold-md-39 {
  @media screen and (min-width: 768px) {
    font: 700 2.44rem/2.4rem Poppins !important;
  }
}
.poppins-semibold-md-40 {
  @media screen and (min-width: 768px) {
    font: 600 2.5rem/2.4rem Poppins !important;
  }
}
.poppins-semibold-md-54 {
  @media screen and (min-width: 768px) {
    font: 600 3.38rem/2.4rem Poppins !important;
  }
}
.bebas-neue-regular-md-21 {
  @media screen and (min-width: 768px) {
    font: 400 1.31rem/1.81rem Bebas Neue !important;
  }
}
.bebas-neue-regular-md-25 {
  @media screen and (min-width: 768px) {
    font: 400 1.56rem/1.81rem Bebas Neue !important;
  }
}
.bebas-neue-regular-md-28 {
  @media screen and (min-width: 768px) {
    font: 400 1.75rem/1.81rem Bebas Neue !important;
  }
}
.bebas-neue-regular-md-38 {
  @media screen and (min-width: 768px) {
    font: 400 2.38rem/2.81rem Bebas Neue !important;
  }
}
.bebas-neue-regular-md-40 {
  @media screen and (min-width: 768px) {
    font: 400 2.5rem/2.81rem Bebas Neue !important;
  }
}
.bebas-neue-regular-md-54 {
  @media screen and (min-width: 768px) {
    font: 400 3.38rem/2.81rem Bebas Neue !important;
  }
}
.work-sans-medium-md-12 {
  @media screen and (min-width: 768px) {
    font: 500 0.75rem/0.44rem Work Sans !important;
  }
}
.work-sans-medium-md-23 {
  @media screen and (min-width: 768px) {
    font: 500 1.44rem/1rem Work Sans !important;
  }
}
.work-sans-bold-md-23 {
  @media screen and (min-width: 768px) {
    font: 700 1.44rem/1rem Work Sans !important;
  }
}
.pacifico-regular-md-20 {
  @media screen and (min-width: 768px) {
    font: 400 1.25rem/1.5rem Pacifico !important;
  }
}
.roboto-regular-md-16 {
  @media screen and (min-width: 768px) {
    font: 400 1rem/1.5rem Roboto !important;
  }
}
.cera-round-pro-medium-md-22 {
  @media screen and (min-width: 768px) {
    font: 1.38rem/1.5rem "Cera Round Pro Medium" !important;
  }
}
.cera-round-pro-medium-md-28 {
  @media screen and (min-width: 768px) {
    font: 1.75rem/1.5rem "Cera Round Pro Medium" !important;
  }
}

.form-select,
.form-control {
  font: 500 0.87rem/0.87rem Poppins;
  color: rgba(0, 0, 0, 1);
}
.input-group-text {
  background-color: white;
}
.main-container {
  background-color: #f9f9f9;
  min-height: 100vh;
}
@media screen and (min-width: 768px) {
  .main-container {
    background-color: #f9f9f9;
    min-height: 100vh;
  }
}
.modal-background {
  background: rgba(0, 0, 0, 0.9) !important;
}
.info-background {
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.property-detail-background {
  @media screen and (min-width: 992px) {
    background: rgba(0, 0, 0, 0.5) !important;
  }
  background: white !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.transparent-background {
  background: rgba(0, 0, 0, 0) !important;
  border: 1px solid rgba(0, 0, 0, 0);
}
.clickable {
  cursor: pointer;
}
.unset-button {
  all: unset;
}
