:root {
  --scroller-snap-strictness: mandatory;
}

.full-space {
  width: 100%;
  height: 100%;
}

.scroller {
  position: relative;
  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.scroller::-webkit-scrollbar {
  width: 0.1em;
}

.scroller-items-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.scroller-container {
  flex-shrink: 0;
}

.scroller-snap-x {
  scroll-snap-type: x var(--scroller-snap-strictness);
  flex-direction: row;
}

.scroller-snap-y {
  flex-direction: column;
  scroll-snap-type: y var(--scroller-snap-strictness);
}

.scroller-scroll-disable {
  overflow: hidden;
}

.scroller-snap-mandatory {
  --scroller-snap-strictness: mandatory;
}

.scroller-snap-proximity {
  --scroller-snap-strictness: proximity;
}

.scroller-snap-always {
  scroll-snap-stop: always;
}

.scroller-snap-normal {
  scroll-snap-stop: normal;
}

.scroller-snap-start {
  scroll-snap-align: start;
}
.scroller-snap-end {
  scroll-snap-align: end;
}
.scroller-snap-center {
  scroll-snap-align: center;
}
